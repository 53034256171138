 <template>
  <div class="input_video">
    <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      @play="onPlayerPlay($event)"
      :options="playerOptions"
    >
    </video-player>
    <div class="tips-box" ref="tipsbox" v-if="busitype=='3' && showtips">
      <div class="tips-top">工作记录</div>
      <div class="tips-ctx">
          <div style="display: flex;"><div class="tips-title">公司名称：</div>杭州普润安华税务师事务所有限公司</div>
          <div style="display: flex;"><div class="tips-title">时间：</div>{{ createtime }}</div>
          <div style="display: flex;"><div class="tips-title">地点：</div>杭州</div>
          <div style="display: flex;"><div class="tips-title">主题：</div>合同约定</div>
          <div style="display: flex;"><div class="tips-title">工作内容：</div>工作记录</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BusImg",
  props: ["videoUrl",'busitype','createtime'],
  data() {
    return {
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src: this.videoUrl, //url地址
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      showtips:false,
      player:null
    };
  },
  mounted(){
    this.player = this.$refs.videoPlayer.player
  },
  methods: {
    //方法
    full(element) {
      //做兼容处理
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen();
      } else {
        var docHtml = document.documentElement;
        var docBody = document.body;
        var videobox = document.getElementsByClassName("video-player");
        var cssText = "width:100%;height:100%;overflow:hidden;";
        docHtml.style.cssText = cssText;
        docBody.style.cssText = cssText;
        videobox.style.cssText = cssText + ";" + "margin:0px;padding:0px;";
        document.IsFullScreen = true;
      }
    },
    onPlayerPlay(player) {
      this.full(player);
      if(this.busitype=='3'){
        this.showtips = true
        this.$nextTick(()=>{
          this.player = this.$refs.videoPlayer.player
          const dom = document.getElementsByClassName("video-js")[0];
          const div = this.$refs.tipsbox
          dom.appendChild(div)
          dom.addEventListener('fullscreenchange',(e)=>{
            if(!this.player.isFullscreen_){
              this.showtips =false
            }
          })
            
        })
      }
    },
  },
};
</script>
<style>
.input_video {
  width: 100%;
  margin: 0 auto;
}
.tips-box{
    position: absolute;
    z-index: 1999;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    margin: 0 auto;
    bottom: 5%;
    font-size: 10px;
    color: #000;
    /* scale: 0.5; */
    left: 0;
}
.tips-ctx{
    display: flex;
    flex-direction: column;
    text-align: right;
    padding: 2px;
}
.tips-top{
    background-color: #4235bd;
    width: 100%;
    padding: 4px 0;
    text-align: center;
    color: #fff;
    border-radius: 4px 4px 0 0 ;
}
.tips-title{
    width: 60px;
}
</style>